import {withRoute} from '@hconnect/uikit'

import {withRouteRequiringPermission} from '../../Molecules/PermissionBoundary'
import {PermissionTypes} from '../../Permissions'

import {FinancePage} from './Finance.container'

export const ROUTE = '/finance'

export const InvoiceRoute = '/invoices'
export const StatementsRoute = '/statements'
export const PaymentsRoute = '/transactions'
export const PayNowRoute = '/paymentRequest'

export default withRouteRequiringPermission({
  path: ROUTE,
  label: 'Finance',
  labelKey: 'invoice.navigationLabel',
  order: 20,
  permissions: [
    PermissionTypes.VIEW_INVOICES,
    PermissionTypes.VIEW_FINANCE,
    PermissionTypes.CHANGE_FINANCE,
    PermissionTypes.VIEW_ALL_DATA
  ],
  featureToggleName: 'Finance'
})(FinancePage)

withRoute({
  path: InvoiceRoute,
  hideInMainNav: true
})(() => null)

withRoute({
  path: `${ROUTE}${PayNowRoute}`,
  labelKey: 'lumpSumPayment.navTitle',
  hideInMainNav: true,
  featureToggleName: 'PayAgainstAccount',
  permissions: [PermissionTypes.CHANGE_FINANCE]
})(() => null)

withRoute({
  path: `${ROUTE}${InvoiceRoute}`,
  labelKey: 'invoice.title',
  hideInMainNav: true,
  permissions: [
    PermissionTypes.VIEW_INVOICES,
    PermissionTypes.VIEW_FINANCE,
    PermissionTypes.VIEW_ALL_DATA
  ]
})(() => null)

withRoute({
  path: `${ROUTE}${StatementsRoute}`,
  labelKey: 'statements.title',
  hideInMainNav: true,
  permissions: [PermissionTypes.VIEW_FINANCE],
  featureToggleName: 'Statements'
})(() => null)

withRoute({
  path: `${ROUTE}${PaymentsRoute}`,
  labelKey: 'payments.title',
  hideInMainNav: true,
  permissions: [PermissionTypes.VIEW_FINANCE],
  featureToggleName: 'Payments'
})(() => null)
