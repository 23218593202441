import {ReceiveInvite} from '@hconnect/common/Invite/Receive/ReceiveInvitePage'
import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {fetchPermissions} from '../../../Permissions/permissions.actions'
import {AppState} from '../../../Root.store'

import {fetchCustomers} from './../../../Organisms/Customers'

export const ReceiveInvitePage: React.FC = () => {
  const loggedInUserId = useSelector<AppState, string>(
    (state) => state.userProfile.userProfile?.user_id || ''
  )

  const userCountry = useSelector((state: AppState) => state.userProfile.userProfile?.country || '')

  const dispatch = useDispatch()

  const handleFetchPermissions = () => {
    dispatch(fetchPermissions(loggedInUserId))
  }

  const handleFetchCustomers = () => {
    dispatch(fetchCustomers(loggedInUserId))
  }

  return (
    <ReceiveInvite
      onFetchPermissions={handleFetchPermissions}
      onFetchCustomers={handleFetchCustomers}
      userId={loggedInUserId}
      userCountryId={userCountry}
    />
  )
}
