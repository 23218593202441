import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {ReactComponent as Cancelled} from '../../../../Assets/paymentIcons/cancelled.svg'
import {ReactComponent as Closed} from '../../../../Assets/paymentIcons/closed.svg'
import {ReactComponent as Open} from '../../../../Assets/paymentIcons/open.svg'
import {ReactComponent as PartiallyPaid} from '../../../../Assets/paymentIcons/partiallyPaid.svg'
import {ReactComponent as Processing} from '../../../../Assets/paymentIcons/processing.svg'
import {Invoice, InvoiceStatus as InvoiceStatusType} from '../Invoice.types'

import {useStyles} from './styles'

export const isPaymentPending = (invoice: Invoice) =>
  !!invoice.lineItems.find((item) => item.isPaymentPending)

interface Type {
  invoice: Invoice
  t: TFunction
}

export const InvoiceStatus: React.FC<Type> = ({invoice, t}) => {
  const classes = useStyles()

  if (invoice.status === InvoiceStatusType.open && isPaymentPending(invoice)) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <Typography
          className={`${classes.processing} ${classes.common}`}
          variant="subtitle2"
          color="textPrimary"
        >
          <Processing />
          {t(`invoice.statuses.${invoice.status}`)}
        </Typography>
        <Typography className={classes.processingCaption}>
          {t('invoice.statuses.processing.title')}
        </Typography>
      </Box>
    )
  }

  switch (invoice.status) {
    case InvoiceStatusType.open:
      return (
        <Typography className={`${classes[invoice.status]} ${classes.common}`} variant="subtitle2">
          <Open />
          {t(`invoice.statuses.${invoice.status}`)}
        </Typography>
      )
    case InvoiceStatusType.cancelled:
      return (
        <Typography className={`${classes[invoice.status]} ${classes.common}`} variant="subtitle2">
          <Cancelled />
          {t(`invoice.statuses.${invoice.status}`)}
        </Typography>
      )
    case InvoiceStatusType.partiallyPaid:
      return (
        <Typography className={`${classes[invoice.status]} ${classes.common}`} variant="subtitle2">
          <PartiallyPaid />
          {t(`invoice.statuses.${invoice.status}`)}
        </Typography>
      )
    case InvoiceStatusType.closed:
      return (
        <Typography
          className={`${classes[invoice.status]} ${classes.common}`}
          variant="subtitle2"
          color="secondary"
        >
          <Closed />
          {t(`invoice.statuses.${invoice.status}`)}
        </Typography>
      )
    default:
      return null
  }
}
