import {Typography, useTranslation} from '@hconnect/uikit'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Box, Button, Skeleton} from '@mui/material'
import moment from 'moment'
import {useHistory} from 'react-router'

import NoInvoices from '../../../../Assets/payments/noInvoices.svg'
import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {useFeaturesState} from '../../../../Organisms/Features'
import {useAccountParams} from '../../Invoices/hooks'
import {payableInvoices} from '../../Invoices/Invoice.utils'
import {useInvoices} from '../hooks/useInvoices'

import {InvoiceToPay} from './InvoiceToPay'

interface ComingUpNextProps {
  customerId?: string
  payerId?: string
}

export const ComingUpNext: React.FC<ComingUpNextProps> = ({customerId, payerId}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const history = useHistory()
  const {getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const excludeLegacyInvoiceFeature = getResolutionByNameAndConstraint(
    'ExcludeLegacySystemsFromPayments',
    normalized,
    'connectorId'
  )
  const accountParams = useAccountParams(payerId, customerId)
  const date = moment().endOf('day')
  const invoicesFilter = {
    customerId: accountParams?.customerId,
    payerId: accountParams?.payerId,
    limit: 30,
    status: 'open,partiallyPaid',
    expand: 'onlinePaymentStatus',
    endDate: date.toISOString(),
    startDate: date.subtract(30, 'days').toISOString()
  }
  const {data, isLoading, isError, isSuccess} = useInvoices(invoicesFilter)

  const navigateToInvoices = (invoiceId: string | undefined = undefined) => {
    const selectedInvoice = invoiceId ? `&selectedInvoice=${invoiceId}` : ''
    history.push(
      `/finance/invoices?startDate=${invoicesFilter.startDate}&endDate=${invoicesFilter.endDate}&status=${invoicesFilter.status}${selectedInvoice}`
    )
  }

  const invoicesToPay =
    isSuccess && data && data.invoices.length > 0
      ? payableInvoices(data.invoices, excludeLegacyInvoiceFeature).slice(0, 3)
      : []

  return (
    <>
      <CustomHeader title={t('paymentConfirmation.upComingNext.title')} />
      {isLoading && <Skeleton height="300px" width="100%" variant="rectangular" />}
      {isError && (
        <Box mt={2}>
          <Typography color="error">
            {t('paymentConfirmation.upComingNext.invoicesError')}
          </Typography>
        </Box>
      )}
      {isSuccess && data && (
        <>
          {invoicesToPay.length === 0 ? (
            <Box display="flex" flexDirection="column" gap={4} sx={{my: 10, alignItems: 'center'}}>
              <img src={NoInvoices} width="103px" alt="No invoices left" />
              <Typography style={{maxWidth: 300, textAlign: 'center'}}>
                {t('paymentConfirmation.upComingNext.noInvoices')}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography>
                {t('paymentConfirmation.upComingNext.description', {
                  invoiceCount: invoicesToPay.length
                })}
              </Typography>
              {invoicesToPay.map((invoice, i) => (
                <InvoiceToPay
                  key={invoice.invoiceId}
                  invoice={invoice}
                  language={language}
                  t={t}
                  showDivider={i < invoicesToPay.length - 1}
                  onClick={() => navigateToInvoices(invoice.invoiceId)}
                />
              ))}
            </>
          )}
          <Box display="flex" flexDirection="column" sx={{mt: 2}}>
            <Button
              startIcon={<ArrowForwardIcon />}
              onClick={() => navigateToInvoices()}
              color="secondary"
              data-test-id="pay-other-invoices-button"
            >
              {t('paymentConfirmation.upComingNext.payOtherInvoices')}
            </Button>
          </Box>
        </>
      )}
    </>
  )
}
