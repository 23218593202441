import {Box, Typography, styled} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import entries from 'lodash/entries'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomHeader} from '../../../../../Molecules/CustomHeader'
import {FieldValue} from '../../../../../Molecules/FieldDetails'
import MaterialDescription from '../../../../../Molecules/MaterialDescription'
import {Invoice, NormalizedAggregate} from '../../Invoice.types'
import {lineItemAggregation} from '../../Invoice.utils'

const Span = (props) => <Typography component="span" {...props} />
const ValueSpan = styled(Span)(() => {
  const style: any = {
    root: {
      lineHeight: '20px'
    }
  }
  return style
})

interface CostItemType {
  label: string
  amount: number
  currency: string
  locale: string
  otherProps?: any
  total?: boolean
  isInvoiceType?: boolean
  customerMaterialDescription?: string
}
export const CostItem: React.FC<CostItemType> = ({
  label,
  amount,
  currency,
  locale,
  total,
  isInvoiceType,
  customerMaterialDescription,
  ...otherProps
}) => (
  <ValueSpan
    color="textPrimary"
    variant="body2"
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: 12,
      marginBottom: total ? 0 : 8,
      fontWeight: total ? 700 : 500,
      fontSize: 16
    }}
    {...otherProps}
  >
    {isInvoiceType ? (
      <Box>
        <MaterialDescription
          materialDescription={label}
          customerMaterialDescription={customerMaterialDescription}
        />
      </Box>
    ) : (
      <span style={{wordBreak: 'break-word'}}>{label}</span>
    )}
    <span>{currencyFormatter.format(amount, {code: currency, locale})}</span>
  </ValueSpan>
)

interface Type {
  invoice: Invoice
  loading?: boolean
}

export const CostSummary: React.FC<Type> = ({invoice, loading}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const [aggregateOnMaterial, setAggregateOnMaterial] = React.useState<NormalizedAggregate>({})

  React.useEffect(() => {
    if (invoice) {
      setAggregateOnMaterial(lineItemAggregation(invoice))
    }
  }, [invoice])

  if (!invoice && !loading) return null
  const {customerMaterialDescription} = invoice?.lineItems[0] || {}
  const isInvoiceType = invoice.type === 'invoice'

  return (
    <Box mb={2} data-test-id="invoice-cost-summary">
      <CustomHeader
        title={isInvoiceType ? t('invoiceDetail.costSummary') : t('invoiceDetail.summary')}
      />
      <FieldValue
        dataTestId="invoice-materials"
        label={isInvoiceType ? t('invoiceDetail.materials') : t('invoiceDetail.description')}
        gutter
      >
        {entries(aggregateOnMaterial).map(([key, val]) => (
          <CostItem
            key={key}
            label={val.materialDescription}
            amount={val.netAmount}
            currency={val.invoiceCurrency}
            locale={language}
            isInvoiceType
            customerMaterialDescription={customerMaterialDescription}
          />
        ))}
      </FieldValue>
      <FieldValue label={t('invoiceDetail.tax')} dataTestId="invoice-tax" gutter>
        <CostItem
          label={t('invoiceDetail.salesTax')}
          amount={invoice.invoiceTaxValue}
          currency={invoice.invoiceCurrency}
          locale={language}
        />
      </FieldValue>
      <Box sx={{backgroundColor: '#ebf3fc', mx: -2, p: 2}}>
        <CostItem
          label={t('invoiceDetail.total')}
          amount={invoice.invoiceGrossValue}
          currency={invoice.invoiceCurrency}
          locale={language}
          data-test-id="invoice-gross-value"
          total={true}
        />
      </Box>
    </Box>
  )
}
