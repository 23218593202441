import {PrimaryCell, dateFormatter, Typography, TableColumnType} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'
import React from 'react'
import {Trans} from 'react-i18next'

import {TooltipColumnLabel} from '../../../Molecules/TooltipColumnLabel'

import {Payment} from './Payments.types'

interface PaymentColumnsType {
  t: TFunction
  language: string
  surchargeEnabled: boolean
}

export const PaymentsColumns = ({t, language, surchargeEnabled}: PaymentColumnsType) => {
  const columnPool: {[key: string]: TableColumnType} = {
    date: {
      field: 'postingDate',
      // renderHeader: (
      //   <TooltipColumnLabel
      //     tLabel={t('payments.postingDate')}
      //     tTooltip={t('payments.postingDateTooltip')}
      //   />
      // ),
      headerName: t('payments.postingDate'),
      sortable: true,
      headerAlign: 'left',
      align: 'left',
      flex: 2,
      renderCell: (item: Payment) => (
        <PrimaryCell>{dateFormatter(item.postingDate, language)}</PrimaryCell>
      )
    },
    amount: {
      field: 'amount',
      headerName: surchargeEnabled ? t('payments.grossWithSurcharge') : t('payments.gross'),
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      flex: 2,
      renderCell: (item: Payment) => {
        const {amount, currency, surchargeItem} = item
        if (surchargeEnabled && surchargeItem) {
          return (
            <PrimaryCell>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Box>
                  <Typography variant="body2" color="textPrimary">
                    {currencyFormatter.format(amount, {
                      code: currency,
                      locale: language
                    })}
                  </Typography>
                </Box>
                <Box data-test-id={`surcharge-amount-${item.paymentId}`}>
                  <Typography variant="subtitle1" color="secondary">
                    <Trans
                      i18nKey="payments.surchargeInformation"
                      values={{
                        amount: currencyFormatter.format(surchargeItem.amount, {
                          code: surchargeItem.currency,
                          locale: language
                        })
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </PrimaryCell>
          )
        }
        return (
          <PrimaryCell>
            {currencyFormatter.format(amount, {
              code: currency,
              locale: language
            })}
          </PrimaryCell>
        )
      }
    },
    references: {
      field: 'references',
      renderHeader: (
        <TooltipColumnLabel
          tLabel={t('payments.references')}
          tTooltip={t('payments.remarkTooltip')}
        />
      ),
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      flex: 3,
      renderCell: (item: Payment) => (
        <>
          <PrimaryCell>
            {t('payments.transaction')} {item.paymentNumber}
          </PrimaryCell>
          <Typography color="secondary" variant="subtitle1">
            {item.remark}
          </Typography>
        </>
      )
    }
  }

  return [columnPool.date, columnPool.references, columnPool.amount]
}
