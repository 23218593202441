/* eslint-disable complexity */
import {User} from '@hconnect/apiclient'
import {Content, Page, Typography} from '@hconnect/uikit'
import {Box, Divider, Grid, Paper} from '@mui/material'
import {History, Location} from 'history'
import isEmpty from 'lodash/isEmpty'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Route} from 'react-router'
import {useHistory} from 'react-router-dom'

import SelectItemPNG from '../../Assets/select-item.png'
import {TrackingPageNames} from '../../common/constants'
import {C60Lead} from '../../Molecules/LeadBanners'
import {PermissionBoundary} from '../../Molecules/PermissionBoundary'
import {PermissionDeniedError} from '../../Molecules/PermissionDeniedError'
import {useResponsiveBreakpoints, useResponsiveGridStyles} from '../../Molecules/Responsive.utils'
import {CashBalanceWidget} from '../../Organisms/CashBalanceWidget'
import {CustomerStateType, selectCustomers} from '../../Organisms/Customers'
import {Features, useFeaturesState} from '../../Organisms/Features'
import {fetchAllPayers, PayersStateType} from '../../Organisms/Payers'
import {selectPayers} from '../../Organisms/Payers/Payers.selectors'
import {PermissionTypes, usePermissions} from '../../Permissions'
import {AppState} from '../../Root.store'

import InvoiceComponentPage from './Invoices/Invoice.container'
import {AsideToggleTypes} from './Invoices/Invoice.types'
import {PaymentsComponentPage} from './Payments'
import {PayNow} from './PayNow'
import {StatementsComponentPage} from './Statements'

import {ROUTE as FinanceRoute, InvoiceRoute, StatementsRoute, PayNowRoute, PaymentsRoute} from '.'

interface FinancePageType {
  filters: any
  location: Location<History>
  clearCurrentView?: any
}

export const FinancePage: React.FC<FinancePageType> = ({location}) => {
  const pathName = location.pathname.replace(FinanceRoute, '')
  const {screenSize, smallScreen, largeScreen} = useResponsiveBreakpoints()
  const history = useHistory()
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const {hasPermission} = usePermissions()
  const [tabValue, setTabValue] = useState<string>(pathName)
  const permissions = [
    PermissionTypes.CHANGE_FINANCE,
    PermissionTypes.VIEW_FINANCE,
    PermissionTypes.VIEW_INVOICES,
    PermissionTypes.VIEW_ALL_DATA
  ]

  const {classes} = useResponsiveGridStyles()
  const height = 'calc(100vh - 220px)'

  const {getFeature, getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const isStatementsEnabled = getFeature('Statements')
  const isPaymentsEnabled = getFeature('Payments')
  const isPayNowEnabled = getFeature('PayAgainstAccount')
  const isCustomerInvoiceNumber = getFeature('CustomerInvoiceNumber')
  const isCustomerDeliveryNumber = getFeature('CustomerDeliveryNumber')
  const useTruckLicensePlate = getFeature('TruckLicensePlate')
  const showState = getFeature('ShowState')
  const isInvoiceDetailsDisabled = (invoiceId: string) =>
    getResolutionByNameAndConstraint('InvoiceDetails', normalized, 'connectorId').disabled.includes(
      invoiceId.split('.')[0]
    ) || !getFeature('Finance')
  const {grantedPermissionTypes} = usePermissions()
  const showCashBalance =
    grantedPermissionTypes.has(PermissionTypes.VIEW_CREDIT) && getFeature('MinifiedCashBalance')

  const payersEnabled = showCashBalance || isStatementsEnabled || isPaymentsEnabled

  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const payersState = useSelector<AppState, PayersStateType>((state) => selectPayers(state))

  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const totalGrossValue = useSelector<AppState, number | undefined>(
    (state) => state.finance.invoices.totalGrossValue
  )
  const {selectedCustomer, isFetching: isCustomersFetching} = customersState
  const {selectedPayer, entities: payers} = payersState

  // const parsed
  interface SubPageType {
    key: string
    label: string
    path: string
    component: React.FC<any>
    permission: PermissionTypes[]
  }
  const payNow: SubPageType = {
    key: 'payments',
    label: t('lumpSumPayment.navTitle'),
    path: PayNowRoute,
    component: PayNow,
    permission: [PermissionTypes.CHANGE_FINANCE]
  }
  const invoices: SubPageType = {
    key: 'invoices',
    label: t('invoice.title'),
    path: InvoiceRoute,
    component: InvoiceComponentPage,
    permission: [
      PermissionTypes.VIEW_INVOICES,
      PermissionTypes.VIEW_FINANCE,
      PermissionTypes.VIEW_ALL_DATA
    ]
  }
  const statements: SubPageType = {
    key: 'statements',
    label: t('statements.title'),
    path: StatementsRoute,
    component: StatementsComponentPage,
    permission: [PermissionTypes.VIEW_FINANCE]
  }
  const payments: SubPageType = {
    key: 'transactions',
    label: t('payments.title'),
    path: PaymentsRoute,
    component: PaymentsComponentPage,
    permission: [PermissionTypes.VIEW_FINANCE]
  }
  const subPages: SubPageType[] = [invoices]

  // feature flag that allows statements
  if (isStatementsEnabled) {
    subPages.push(statements)
  }
  if (isPaymentsEnabled) {
    subPages.push(payments)
  }
  if (isPayNowEnabled) {
    subPages.unshift(payNow)
  }
  const filteredSubPages = subPages.filter((page) =>
    page.permission.some((permission) => hasPermission(permission))
  )

  const pageTitle =
    filteredSubPages.find((page) => page.path === tabValue)?.label || t('finance.title')

  useEffect(() => {
    if (!isCustomersFetching && selectedCustomer && payersEnabled && isEmpty(payers)) {
      dispatch(fetchAllPayers())
    }
  }, [dispatch, isCustomersFetching, payers, payersEnabled, selectedCustomer])

  useEffect(() => {
    if (!pathName) {
      const defaultPath = filteredSubPages[0].path
      history.push(`${FinanceRoute}${defaultPath}`)
      setTabValue(defaultPath)
    } else {
      setTabValue(pathName)
    }
  }, [pathName, history, filteredSubPages])

  const [collapseCreditWidget, setCollapseCreditWidget] = useState(false)
  const onGridScroll = (event: React.UIEvent) => {
    const delay = 100
    if (event.currentTarget.clientHeight === event.currentTarget.scrollHeight) {
      return
    }

    if (event.currentTarget.scrollTop === 0) {
      setTimeout(() => setCollapseCreditWidget(false), delay)
    } else {
      setTimeout(() => setCollapseCreditWidget(true), delay)
    }
  }

  const analytics = {
    userId: user?.user_id,
    userMail: user?.eMail,
    userCountry: user?.country,
    userRoles: user?.hasRoles,
    userIsInternal: user?.isInternal,
    customerId: selectedCustomer?.customerId,
    customerName: selectedCustomer?.customerName
  }

  const toggles: AsideToggleTypes = {
    isCustomerInvoiceNumber,
    showState,
    isCustomerDeliveryNumber,
    useTruckLicensePlate,
    isInvoiceDetailsDisabled
  }

  const rightPanelTitle = (title: string) => (
    <>
      <Typography variant="h4">{t(title)}</Typography>
      <Box mt={1} />
      <Divider />
    </>
  )

  return (
    <Content style={{padding: smallScreen ? '8px' : 0}}>
      <PermissionBoundary
        requirePermission={permissions}
        renderFallback={() => <PermissionDeniedError />}
      >
        <Page
          data-test-id="finance-page"
          title={pageTitle}
          headerActionContent={<div />}
          boxed={false}
          variant={smallScreen ? 'default' : 'withDetails'}
          {...(smallScreen ? {px: 0, py: 0} : {py: 1, pb: 0})}
        >
          <Grid
            container
            spacing={2}
            style={{
              position: 'relative',
              marginTop: 0,
              minHeight: height,
              overflowY: 'auto'
            }}
          >
            <Grid
              item
              className={classes.gridItem}
              lg={largeScreen && tabValue !== payNow.path ? 8 : 12}
              md={12}
              xs={12}
              sm={12}
              style={{
                maxHeight: !smallScreen ? height : 'initial',
                padding: 0,
                paddingLeft: '16px',
                marginBottom: smallScreen ? '8px' : 0,
                ...(!smallScreen ? {overflow: 'visible'} : {overflowY: 'auto'})
              }}
              onScroll={onGridScroll}
            >
              {smallScreen && selectedPayer && showCashBalance && tabValue !== payNow.path ? (
                <Box mb={2} style={{position: 'sticky', top: 0, zIndex: 300}}>
                  <Paper elevation={4} sx={{py: 2}}>
                    <CashBalanceWidget
                      minified
                      isCustomersFetching={isCustomersFetching}
                      customer={selectedCustomer}
                      onRetry={() => {}}
                      smallScreen={smallScreen}
                      collapse={collapseCreditWidget}
                    />
                  </Paper>
                </Box>
              ) : null}
              {subPages.map((page) => {
                if (!filteredSubPages.includes(page)) {
                  return null
                }
                const SubPageComponent = page.component
                return (
                  <Route
                    key={page.path.replace(/\//gm, '')}
                    exact
                    path={`${FinanceRoute}${page.path}`}
                    render={() => <SubPageComponent analytics={analytics} toggles={toggles} />}
                  />
                )
              })}
            </Grid>
            {largeScreen && tabValue !== payNow.path ? (
              <Grid
                item
                lg={4}
                xl={4}
                style={{
                  maxHeight: height,
                  paddingTop: 0,
                  paddingBottom: 0
                }}
                data-test-id="payment-details"
              >
                <Paper
                  elevation={4}
                  color="white"
                  className={classes.gridItem}
                  style={{height: '100%', overflowY: 'auto', padding: '8px', marginBottom: '4px'}}
                >
                  <Box display="flex" flexDirection="column" minHeight="100%" minWidth="100%" p={2}>
                    <Box data-test-id="finance-widget" mt={2}>
                      <Features name="C60LeadWidget">
                        {rightPanelTitle('finance.titleForC60')}
                        <Box mb={6} mt={4} style={{alignSelf: 'center'}}>
                          <C60Lead
                            countryCode={user?.country || ''}
                            fullScreen={smallScreen}
                            customerId={selectedCustomer?.customerId || ''}
                            analytics={analytics}
                            isMediumScreen={screenSize === 'md'}
                            screenSize={screenSize}
                            contextualC60Widget
                            totalGrossValue={totalGrossValue}
                            entryPoint={TrackingPageNames.FINANCE_PAGE}
                          />
                        </Box>
                      </Features>
                      {showCashBalance && selectedPayer ? (
                        <>
                          {rightPanelTitle('finance.summary')}
                          <Box my={2}>
                            <CashBalanceWidget
                              minified
                              isCustomersFetching={isCustomersFetching}
                              customer={selectedCustomer}
                              smallScreen={smallScreen}
                            />
                          </Box>
                        </>
                      ) : null}
                    </Box>

                    <Box
                      flexGrow={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {(!showCashBalance || !selectedPayer) && tabValue === invoices.path && (
                        <>
                          <Box height={210}>
                            <img src={SelectItemPNG} alt="" height="auto" width="auto" />
                          </Box>
                          <Box width="60%" my={3}>
                            <Typography variant="body1" align="center">
                              {t('invoiceDetail.selectInvoice')}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ) : null}
          </Grid>
        </Page>
      </PermissionBoundary>
    </Content>
  )
}
