import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {CustomerStateType} from '../../../../Organisms/Customers'
import {useFeaturesState} from '../../../../Organisms/Features'
import {PayersStateType, selectPayers} from '../../../../Organisms/Payers'
import {AppState} from '../../../../Root.store'

export interface AccountParams {
  payerId?: string
  customerId?: string
}

export const useAccountParams = (
  defaultPayerId?: string,
  defaultCustomerId?: string
): AccountParams | undefined => {
  const {getFeature} = useFeaturesState()
  const invoicesByPayer = getFeature('InvoicesByPayer')

  const {selectedCustomer} = useSelector<AppState, CustomerStateType>((state) => state.customers)
  const {selectedPayer} = useSelector<AppState, PayersStateType>((state) => selectPayers(state))

  const [params, setParams] = useState<AccountParams | undefined>(undefined)
  useEffect(() => {
    const customerId = selectedCustomer?.customerId || defaultCustomerId
    const payerId = invoicesByPayer ? selectedPayer?.payerId || defaultPayerId : undefined
    const newParams = payerId === customerId ? {payerId} : {customerId}

    if (newParams.customerId !== params?.customerId || newParams.payerId !== params?.payerId) {
      setParams(newParams)
    }
  }, [invoicesByPayer, selectedCustomer, selectedPayer, params, defaultCustomerId, defaultPayerId])

  return params
}
