import CloseIcon from '@mui/icons-material/Close'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import {Grid, Box, Divider, IconButton, Skeleton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {Features} from '../../../../Organisms/Features/Features'
import {useAccountParams, useDeliveriesByInvoiceId, useSurchargeDeliveries} from '../hooks'
import {AsideToggleTypes, FinanceAnalytics, Invoice} from '../Invoice.types'
import {findInvoiceDocument} from '../Invoice.utils'

import {InvoiceHeader, ServiceSurchargeItemsList, useIconStyles} from './components'
import {CostSummary} from './components/CostSummary'
import {DeliveriesLineItemList} from './components/DeliveriesLineItemList'
import {LineItemList} from './components/LineItemList'

interface InvoiceDetailsAsideProps {
  invoice: Invoice
  download?: () => void
  expand: () => void
  close: () => void
  isMobile: boolean
  customerId: string
  payerId?: string
  analytics: FinanceAnalytics
  aside?: boolean
  toggles: AsideToggleTypes
}
// eslint-disable-next-line complexity
export const InvoiceDetailsAside: React.FC<InvoiceDetailsAsideProps> = ({
  expand,
  close,
  invoice,
  customerId,
  payerId,
  toggles,
  aside = false,
  analytics,
  download
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  // Add all needed feature toggles and permissions
  const {classes} = useIconStyles()
  const accountParams = useAccountParams(payerId, customerId)

  const {
    data,
    error: deliveriesError,
    isFetching: deliveriesFetching
  } = useDeliveriesByInvoiceId({
    accountParams,
    invoiceId: invoice.invoiceId,
    invoice
  })

  const {deliveries, deliveriesWithLineItems} = data || {}
  const {serviceItems, surchargeItems} = invoice
  const materialItems = deliveriesWithLineItems?.filter(
    (d) =>
      d.lineItemTypes.includes('material') ||
      d.lineItemTypes.includes('default') ||
      toggles.isInvoiceDetailsDisabled(invoice.invoiceId)
  )
  const canDownload = findInvoiceDocument(invoice) !== undefined && download

  const {
    data: surchargeDeliveries,
    isFetching: surchargeDeliveriesFetching,
    isError: surchargeDeliveriesError
  } = useSurchargeDeliveries({
    surcharges: surchargeItems
  })
  return (
    <Box p={4} height="100%">
      <CustomHeader title={`${t(`invoice.types.${invoice.type}`)} ${t('invoice.expand.summary')}`}>
        {canDownload ? (
          <IconButton
            className={classes.icon}
            size="large"
            onClick={() => download()}
            data-test-id="invoice-download"
          >
            <SaveAltIcon />
          </IconButton>
        ) : null}
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => expand()}
          data-test-id="invoice-full-details"
        >
          <OpenInFullIcon />
        </IconButton>
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => close()}
          data-test-id="invoice-details-close"
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>
      <Grid container>
        <Grid item xs={6}></Grid>
        <Grid item xs={6} style={{marginTop: 8}}></Grid>
      </Grid>
      <InvoiceHeader
        invoice={invoice}
        t={t}
        language={language}
        isCustomerInvoiceNumber={toggles.isCustomerInvoiceNumber}
        showState={toggles.showState}
        aside={aside}
      />
      <Box data-test-id="delivery-progress-bar" />
      {deliveries?.length ? <Divider variant="middle" /> : null}
      <Box my={1} />
      {deliveriesFetching || surchargeDeliveriesFetching ? (
        <Skeleton height="300px" width="100%" variant="rectangular" />
      ) : null}
      {materialItems?.length && invoice.type === 'invoice' ? (
        <DeliveriesLineItemList
          deliveriesWithLineItems={materialItems}
          toggles={toggles}
          analytics={analytics}
          loading={deliveriesFetching || surchargeDeliveriesFetching}
          error={deliveriesError || surchargeDeliveriesError}
          customerId={customerId}
        />
      ) : null}
      <Features name="EnableLineItemCardsForCreditAndDebitNotes">
        {invoice.lineItems?.length && invoice.type === 'creditNote' ? (
          <LineItemList lineItems={invoice.lineItems} currency={invoice.invoiceCurrency} />
        ) : null}
      </Features>
      {serviceItems.length && invoice.type === 'invoice' ? (
        <ServiceSurchargeItemsList
          items={serviceItems}
          toggles={toggles}
          analytics={analytics}
          t={t}
          deliveries={deliveries || []}
          type="services"
        />
      ) : null}
      {surchargeItems.length && invoice.type === 'invoice' ? (
        <ServiceSurchargeItemsList
          items={surchargeItems}
          toggles={toggles}
          analytics={analytics}
          t={t}
          deliveries={deliveries || []}
          surchargeRelatedDeliveries={surchargeDeliveries}
          type="surcharges"
        />
      ) : null}
      <CostSummary invoice={invoice} />
    </Box>
  )
}
