import {GridRowsProp} from '@hconnect/uikit'
import {BreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Box} from '@mui/material'
import React from 'react'

import {TrackingPageNames} from '../../../../common/constants'
import {CustomSkeleton} from '../../../../Molecules/CustomSkeleton'
import {C60Lead} from '../../../../Molecules/LeadBanners'
import {Features} from '../../../../Organisms/Features'
import {Analytics} from '../Statements.columns'
import {Statement} from '../Statements.types'

import {StatementCard} from './StatementCard'

interface Type {
  loading?: boolean
  rows: GridRowsProp<Statement>
  keyField: string
  downloadDocuments: (jobId: string, documentId: string, fileName: string) => void
  analytics: Analytics
  fullScreen: boolean
  screenSize: BreakPoint
}

export const StatementsCardList: React.FC<Type> = ({
  rows,
  loading,
  keyField,
  analytics,
  downloadDocuments,
  fullScreen,
  screenSize
}) => {
  if (loading) {
    return <CustomSkeleton />
  }
  return (
    <Box height="100%" width="100%">
      {rows.length === 0 ? (
        <Features name="C60LeadWidget">
          <C60Lead
            countryCode={analytics.userCountry || ''}
            fullScreen={fullScreen}
            customerId={analytics.customerId || ''}
            analytics={analytics}
            isMediumScreen={screenSize === 'md'}
            screenSize={screenSize}
            entryPoint={TrackingPageNames.STATEMENTS}
          />
        </Features>
      ) : (
        rows.map((row, index) => (
          <Box key={row[keyField]}>
            <StatementCard
              statement={row}
              analytics={analytics}
              downloadDocuments={downloadDocuments}
            />
            <Features name="C60LeadWidget">
              {index === 0 ? (
                <C60Lead
                  countryCode={analytics.userCountry || ''}
                  fullScreen={fullScreen}
                  customerId={analytics.customerId || ''}
                  analytics={analytics}
                  isMediumScreen={screenSize === 'md'}
                  screenSize={screenSize}
                  entryPoint={TrackingPageNames.STATEMENTS}
                />
              ) : null}
            </Features>
          </Box>
        ))
      )}
    </Box>
  )
}
