import {GridRowsProp} from '@hconnect/uikit'
import {BreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Box} from '@mui/material'
import React from 'react'

import {TrackingPageNames} from '../../../../common/constants'
import {CustomSkeleton} from '../../../../Molecules/CustomSkeleton'
import {C60Lead} from '../../../../Molecules/LeadBanners'
import {Features} from '../../../../Organisms/Features'
import {Payment} from '../Payments.types'

import {PaymentCard} from './PaymentCard'

interface Type {
  loading?: boolean
  rows: GridRowsProp<Payment>
  keyField: string
  surchargeEnabled: boolean
  analytics: {
    userId: string | undefined
    userMail: string | null | undefined
    userCountry: string | null | undefined
    userRoles: any[] | undefined
    userIsInternal: boolean | undefined
    customerId: string | undefined
    customerName: string | undefined
    filteredDateRange?: {
      startDate: any
      endDate: any
    }
  }
  fullScreen: boolean
  screenSize: BreakPoint
}

export const PaymentsCardList: React.FC<Type> = ({
  rows,
  loading,
  keyField,
  surchargeEnabled,
  analytics,
  screenSize,
  fullScreen
}) => {
  if (loading) {
    return <CustomSkeleton />
  }
  return (
    <Box height="100%" width="100%">
      {rows.length === 0 ? (
        <Features name="C60LeadWidget">
          <C60Lead
            countryCode={analytics.userCountry || ''}
            fullScreen={fullScreen}
            customerId={analytics.customerId || ''}
            analytics={analytics}
            isMediumScreen={screenSize === 'md'}
            screenSize={screenSize}
            entryPoint={TrackingPageNames.PAYMENTS}
          />
        </Features>
      ) : (
        rows.map((row, index) => (
          <Box key={row[keyField]}>
            <PaymentCard payment={row} surchargeEnabled={surchargeEnabled} />
            <Features name="C60LeadWidget">
              {index === 0 ? (
                <C60Lead
                  countryCode={analytics.userCountry || ''}
                  fullScreen={fullScreen}
                  customerId={analytics.customerId || ''}
                  analytics={analytics}
                  isMediumScreen={screenSize === 'md'}
                  screenSize={screenSize}
                  entryPoint={TrackingPageNames.PAYMENTS}
                />
              ) : null}
            </Features>
          </Box>
        ))
      )}
    </Box>
  )
}
